<template>
  <div class="recycling-waste" style="background-color: #f8fcff;">
    <!-- <div class="banner">
    </div> -->
    <div class="banner">
      <el-carousel class="banner-carousel" indicator-position="outside">
        <el-carousel-item
          v-for="item in dashboardData.photos"
          :title="item.value3"
          :key="item.id"
        >
          <img
            :title="item.value3"
            :src="item.path"
            @click="linkToResolve(item.value4)"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <el-card :body-style="{padding:'0' } " style="width:70.5%;margin: 20px auto;" class="box-card">
			<div class="car-header">
				<p class="p1">二手车交易</p>
				<p class="p2"></p>
				<p class="p3">
					公司长期从事二手车销售，主营品牌有东风本田、东风日产、上汽大众、通用别克、一汽大众等品牌数十种车型。一流的品质，合理的价格，期待为您服务。
				</p>
				<div class="waste-hot">
					<span class="phone">咨询热线：</span>
					<span class="phone">张良文 15102940777</span>
				</div>
    	</div>
      <div class="lastBox-right">
        <el-row :gutter="20" class="recycling-waste-list" v-if="value === '1'">
					<el-col :span="8" class="recycling-waste-item" v-for="(item, index) in steelScrapImageList"  :key="index">
						<img :src="item.src" alt=""/>
						<span class="recycling-waste-text">{{item.text}}</span>
					</el-col>
				</el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "adressMaintain",
  data() {
    return {
			value: '1',
			steelScrapImageList: [
				{
					src: '/img/car/used-car (1).png',
					text: '经典轩逸'
				},
				{
					src: '/img/car/used-car (2).png',
					text: '本田CRV'
				},
				{
					src: '/img/car/used-car (4).png',
					text: '上汽大众帕萨特'
				},
				{
					src: '/img/car/used-car (3).png',
					text: '一汽大众探岳'
				},
				{
					src: '/img/car/used-car (5).png',
					text: '大众朗逸'
				},
			]
    };
  },
  computed: {
    ...mapState({
      dashboardData: (state) => {
        return state.home.dashboardData;
      },
      loading: (state) => {
        return state.loading;
      },
    }),
  },
  created() {
    this.value = this.$route.query.value;
    this.active = this.$route.query.value;
    if (this.value == undefined) {
      this.value = '1';
    }
    if (this.active == undefined) {
      this.active = "1";
    }
    this.url = process.env.VUE_APP_API_BASE_URL;
  },
  methods: {
		handClick(index) {
      this.value = `${index}`;
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  text-align: center;
  height: 480px;
  // background-image: url(/img/lianxibg.png);
  // background-size: 100%;
  // line-height: 480px;
  .banner-carousel {
    width: 100%;
    height: 480px; /* no */
    position: relative;
    /deep/.el-carousel__container {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
    /deep/.el-carousel__indicators--outside {
      position: absolute;
      left: 0;
      width: 100%;
      .el-carousel__button {
        height: 5px; /* no */
      }
    }
  }
}
.car-header {
  height: 180px;
  background-image: url(/img/pic.png);
	background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 30px 0 0 100px;

  .p1 {
    font-size: 36px;
    font-family: YouSheBiaoTi;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    // text-shadow: 0px 2px 11px rgba(4, 23, 117, 0.88);
    background: linear-gradient(0deg, #a2ffce 0%, #ecffff 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    // font-style: oblique;
    margin-bottom: 16px;
  }

  .p2 {
    width: 36px;
    height: 4px;
    background: linear-gradient(90deg, #26faff 0%, #2cff99 100%);
    margin-bottom: 12px;
  }

  .p3, .waste-hot {
    width: 726px;
    font-size: 18px;
    font-family: SourceHanSansCN-Light;
    font-weight: 300;
    color: #ffffff;
    line-height: 26px;
  }
	.waste-hot{
		padding-top: 8px;
	}
	.phone{
		text-indent: 4em;
		font-weight: bold;
		font-size: 24px;
		color: rgb(255, 102, 0);
	}
}
.lastBox-right {
	padding: 2%;
  overflow: hidden;
}
.enterprise {
  margin: 0 auto;
  .p1 {
    span {
      font-size: 14px;
      margin-right: 29px;
    }
  }
}
.recycling-waste-list{
	.recycling-waste-item{
		text-align: center;
		font-size: 20px;
		>img {
			width: 100%;
			height: 300px;
		}
		>span{
			display: inline-block;
			line-height: 50px;
		}
	}
}
.waste-tip{
	text-align: center;
	max-width: 800px;
	margin: 0 auto;
	font-size: 24px;
	padding-top: 20px;
	padding-bottom: 30px;
	line-height: 30px;
	.waste-hot{
		padding-top: 6px;
	}
	.phone{
		text-indent: 4em;
		font-weight: bold;
		font-size: 30px;
		color: rgb(255, 102, 0);
	}
}
/deep/.el-menu-item.is-active {
  background-color: #24a2aa !important;
  color: white !important;
}
/deep/.el-timeline-item__timestamp.is-top {
  position: absolute;
  left: -117px;
  top: -3px;
  color: #333333;
}
/deep/.el-timeline {
  padding-left: 150px;
}
.vjs-custom-skin {
  width: 100%;
  height: 100%;
}
</style>
